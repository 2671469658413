





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { PullRequest } from '@/models/index'
import { Notification } from 'element-ui'
import { fixWaiting } from '@/api/pull_requests'

@Component({
  name: 'ActionsPullRequest',
})
export default class extends Vue {
  @Prop({ required: true }) private pullRequest!: PullRequest

  visible = false

  async fixWaiting() {
    try {
      await fixWaiting()
      this.$emit('refresh')
    } catch (error) {
      Notification({
        title: 'Error',
        message: ' Une erreur s\'est produite ',
        type: 'error',
        duration: 5000
      })
    }
  }
}
